var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-pro-recipe-card" },
    [
      _c(
        "router-link",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: _vm.$utilities.getRippleOptions("light"),
              expression: "$utilities.getRippleOptions('light')",
            },
          ],
          attrs: { to: _vm.detailPagePath },
        },
        [
          _vm.content.image_url
            ? _c("div", { staticClass: "dp-pro-recipe-card__thumbnail" }, [
                _c("img", {
                  attrs: { src: _vm.content.image_url, alt: _vm.content.name },
                  on: { load: _vm.onImgLoaded },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "dp-pro-recipe-card__content" }, [
            _vm.content.tag
              ? _c(
                  "div",
                  { staticClass: "dp-pro-recipe-card__taste" },
                  [
                    _c("DripPodTasteTag", {
                      attrs: { "type-id": _vm.content.tag },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.content.appeal_word
              ? _c("p", { staticClass: "dp-pro-recipe-card__subtitle" }, [
                  _vm._v(_vm._s(_vm.content.appeal_word) + " "),
                ])
              : _vm._e(),
            _vm.content.name
              ? _c("h2", { staticClass: "dp-pro-recipe-card__title" }, [
                  _vm._v(_vm._s(_vm.content.name) + " "),
                ])
              : _vm._e(),
            _vm.content.capsule && _vm.isPublishPeriod(_vm.content.capsule)
              ? _c(
                  "div",
                  { staticClass: "dp-pro-recipe-card__capsule" },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "capsuleR",
                        color: "dpGrey66",
                        width: 15,
                        height: 15,
                      },
                    }),
                    _c(
                      "span",
                      { staticClass: "dp-pro-recipe-card__capsule-name" },
                      [_vm._v(_vm._s(_vm.content.capsule.name) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.content.barista && _vm.isPublishPeriod(_vm.content.barista)
              ? _c("p", { staticClass: "dp-pro-recipe-card__pro" }, [
                  _c("span", { staticClass: "dp-pro-recipe-card__pro-by" }, [
                    _vm._v("by"),
                  ]),
                  _c("span", { staticClass: "dp-pro-recipe-card__pro-name" }, [
                    _vm._v(_vm._s(_vm.content.barista.name_alphabetical) + " "),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }