<template>
  <div class="dp-pro-recipe-card">
    <router-link
      :to="detailPagePath"
      v-ripple="$utilities.getRippleOptions('light')">
      <div v-if="content.image_url" class="dp-pro-recipe-card__thumbnail">
        <img :src="content.image_url" :alt="content.name" @load="onImgLoaded" />
      </div>

      <div class="dp-pro-recipe-card__content">
        <div v-if="content.tag" class="dp-pro-recipe-card__taste">
          <DripPodTasteTag :type-id="content.tag" />
        </div>

        <p v-if="content.appeal_word" class="dp-pro-recipe-card__subtitle">
          <!---->{{ content.appeal_word }}
        </p>

        <h2 v-if="content.name" class="dp-pro-recipe-card__title">
          <!---->{{ content.name }}
        </h2>

        <div
          v-if="content.capsule && isPublishPeriod(content.capsule)"
          class="dp-pro-recipe-card__capsule">
          <Icon name="capsuleR" color="dpGrey66" :width="15" :height="15" />
          <span class="dp-pro-recipe-card__capsule-name">
            <!---->{{ content.capsule.name }}
          </span>
        </div>

        <p
          v-if="content.barista && isPublishPeriod(content.barista)"
          class="dp-pro-recipe-card__pro">
          <span class="dp-pro-recipe-card__pro-by">by</span>
          <span class="dp-pro-recipe-card__pro-name">
            <!---->{{ content.barista.name_alphabetical }}
          </span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  components: {
    DripPodTasteTag: () => import('@/components/dripPod/proRecipe/TasteTag.vue')
  },

  props: {
    content: {
      type: Object,
      default: undefined
    }
  },

  setup(props, context) {
    const { isPublishPeriod } = usePublishable();
    const detailPagePath = computed(
      () => `/drip-pod/pro-recipe/detail/${props.content.id}`
    );

    const onImgLoaded = () => {
      context.emit('on-img-loaded');
    };

    return {
      isPublishPeriod,
      detailPagePath,
      onImgLoaded
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-card {
  background-color: variables.$dpWhite01;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$dpGreyEa;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: grid;
    place-items: start;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 0;
    padding: 16px;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    color: variables.$dpBlack01;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    width: 100%;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__taste {
    margin-bottom: 4px;
  }

  &__subtitle {
    margin-bottom: 4px;
    font-size: 12px;
    color: variables.$dpGrey66;
    white-space: pre-wrap;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 1.3;
    font-weight: bold;
    white-space: pre-wrap;
  }

  &__capsule {
    display: grid;
    place-items: start;
    grid-template-columns: 16px 1fr;
    gap: 3px;
    margin-bottom: 12px;
    color: variables.$dpGrey66;
    font-size: 12px;

    &-name {
      line-height: 1.2;
      white-space: pre-wrap;
    }
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(160, 160);

    overflow: hidden;
  }

  &__pro {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 1.2;
    color: variables.$dpGrey66;

    &-by,
    &-name {
      place-content: center end;
      text-align: left;
      white-space: pre-wrap;
    }

    &-by {
      margin-right: 4px;
      min-width: 1.2em;
    }
  }
}
</style>
